export default class Sticky {
    constructor() {
        this.init();
    }

    init() {
        const h = document.getElementById("site-navigations-sticky"),
            scrollUp = "scroll-up",
            scrollDown = "scroll-down";
        let lastScroll = 0,
            viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        if (viewportWidth <= 1200) {
            window.onscroll = function (e) {
                const currentScroll = window.pageYOffset;
                if (currentScroll <= 75) {
                    h.classList.remove(scrollUp);
                    h.classList.add(scrollDown);
                    return;
                }

                if (currentScroll > lastScroll) {
                    // down
                    h.classList.remove(scrollDown);
                    h.classList.add(scrollUp);
                } else {
                    // up
                    h.classList.remove(scrollUp);
                    h.classList.add(scrollDown);
                }
                lastScroll = currentScroll;
            }
        } else {
            window.onscroll = function (e) {
                const currentScroll = window.pageYOffset;
                if (currentScroll <= 180) {
                    h.classList.remove("hide");
                    h.classList.remove(scrollUp);
                    return;
                }

                if (currentScroll > lastScroll && !h.classList.contains(scrollDown)) {
                    // down
                    h.classList.remove("hide");
                    h.classList.remove(scrollUp);
                    h.classList.add(scrollDown);
                } else if (currentScroll < lastScroll && h.classList.contains(scrollDown)) {
                    // up
                    h.classList.remove(scrollDown);
                    h.classList.add(scrollUp);
                }
                lastScroll = currentScroll;
            }
        }
    }
}