import {tns} from 'tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

export default class Carousel {
    constructor() {
        this.init();
    }

    init() {
        const jsSliderProducts = document.querySelector('.js-slider-products');
        if (jsSliderProducts) {
            tns({
                container: jsSliderProducts,
                items: 1,
                gutter: 5,
                edgePadding: 15,
                loop: true,
                nav: false,
                mouseDrag: true,
                prevButton: '.js-slider-products__slider-nav .slider-prev',
                nextButton: '.js-slider-products__slider-nav .slider-next',
                responsive: {
                    767: {
                        items: 2,
                        gutter: 20,
                        edgePadding: 40
                    },
                    980: {
                        items: 3
                    },
                    1025: {
                        items: 4
                    }
                }
            });
        }

        const jsSliderCorrespondingProducts = document.querySelectorAll('.js-slider-corresponding-products');
        if (jsSliderCorrespondingProducts) {
            jsSliderCorrespondingProducts.forEach(slider => {
                tns({
                    container: slider,
                    items: 1,
                    gutter: 20,
                    loop: true,
                    nav: false,
                    mouseDrag: true,
                    prevButton: slider.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.js-slider-corresponding-products__slider-nav .slider-prev'),
                    nextButton: slider.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.js-slider-corresponding-products__slider-nav .slider-next'),
                    responsive: {
                        767: {
                            items: 2
                        },
                        980: {
                            items: 3
                        }
                    }
                });
            });
        }

        const jsSliderCollaborators = document.querySelector('.js-slider-collaborators');
        if (jsSliderCollaborators) {
            tns({
                container: jsSliderCollaborators,
                items: 1,
                gutter: 20,
                edgePadding: 40,
                loop: false,
                nav: true,
                controls: true,
                mouseDrag: true,
                prevButton: '.js-slider-collaborators__slider-nav .slider-prev',
                nextButton: '.js-slider-collaborators__slider-nav .slider-next',
                responsive: {
                    767: {
                        items: 2.15
                    }
                }
            });
        }

        const jsSliderOurBrands = document.querySelector('.js-slider-our-brands');
        if (jsSliderOurBrands) {
            tns({
                container: jsSliderOurBrands,
                items: 1,
                gutter: 20,
                loop: true,
                nav: false,
                mouseDrag: true,
                prevButton: '.js-slider-our-brands__slider-nav .slider-prev',
                nextButton: '.js-slider-our-brands__slider-nav .slider-next',
                responsive: {
                    980: {
                        items: 3
                    }
                }
            });
        }

        const jsSliderOurManagementCommittee = document.querySelector('.js-slider-our-management-committee');
        if (jsSliderOurManagementCommittee) {
            tns({
                container: jsSliderOurManagementCommittee,
                items: 1.5,
                gutter: 20,
                loop: true,
                nav: false,
                mouseDrag: true,
                prevButton: '.js-slider-our-management-committee__slider-nav .slider-prev',
                nextButton: '.js-slider-our-management-committee__slider-nav .slider-next',
                responsive: {
                    980: {
                        items: 3
                    },
                    1200: {
                        items: 4
                    }
                }
            });
        }

        const jsSliderGallery = document.querySelectorAll('.js-slider-gallery');
        if (jsSliderGallery) {
            jsSliderGallery.forEach(slider => {
                tns({
                    container: slider,
                    items: 1,
                    controls: true,
                    loop: true,
                    nav: true,
                    mouseDrag: true,
                    prevButton: slider.parentElement.parentElement.parentElement.parentElement.querySelector('.js-slider-gallery__slider-nav .slider-prev'),
                    nextButton: slider.parentElement.parentElement.parentElement.parentElement.querySelector('.js-slider-gallery__slider-nav .slider-next')
                });

                console.log(slider.parentElement.parentElement.parentElement.parentElement.querySelector('.js-slider-gallery__slider-nav .slider-prev'));
            });
        }
    }
}