export default class Tabs {
    constructor() {
        this.init();
    }

    init() {
        const anchorReferenceOnLoad = window.location.hash.replace('#', ''),
            expertiseFiltersItemsItemLink = document.querySelector('.expertise-filters__filters-items-item-link'),
            selectExpertiseFiltersItemsItemLink = document.querySelector('select[name="expertise-filters__filters-items-item-link"]'),
            templateContactFormObjectItem = document.querySelector('.template-contact__form-objects-item'),
            templateListFiltersTypeOfApplications = document.querySelector('select[name="template-list__filters-type-of-applications"]');
        let goTabs = (buttons, content) => {
            if (buttons) {
                let tabs = document.querySelectorAll(buttons + " a");

                for (let i = 0; i < tabs.length; i++) {
                    tabs[i].addEventListener("click", function (tabClickEvent) {
                        tabClickEvent.preventDefault();
                        let i;
                        for (i = 0; i < tabs.length; i++) {
                            tabs[i].classList.remove("active");
                        }
                        const clickedTab = tabClickEvent.currentTarget;
                        clickedTab.classList.add("active");
                        const contentPanes = document.querySelectorAll(content);
                        for (i = 0; i < contentPanes.length; i++) {
                            contentPanes[i].classList.remove("active");
                        }

                        const anchorReference = tabClickEvent.target;
                        const activePaneId = anchorReference.getAttribute("href");
                        const activePane = document.querySelector(activePaneId);

                        //console.log(anchorReference);
                        //console.log(activePaneId);

                        activePane.classList.add("active");
                    });
                }
            }
        }

        goTabs('.nav-tabs', '.tab-pane');

        if (anchorReferenceOnLoad && expertiseFiltersItemsItemLink) {
            document.querySelector('.expertise-filters__filters-items-item-link[href="#' + anchorReferenceOnLoad + '"]').click();
        } else if (anchorReferenceOnLoad && templateContactFormObjectItem) {
            document.querySelector('.template-contact__form-objects-item[href="#' + anchorReferenceOnLoad + '"]').click();
        }

        if (selectExpertiseFiltersItemsItemLink) {
            selectExpertiseFiltersItemsItemLink.addEventListener("change", function () {
                document.querySelector('.expertise-filters__filters-items-item-link[href="#' + this.value + '"]').click();
                document.querySelector('input[name="filter_expertise"][value="' + this.value + '"]').click();
            });
        } else if (templateListFiltersTypeOfApplications) {
            templateListFiltersTypeOfApplications.addEventListener("change", function () {
                location.href = this.value;
            });
        }
    }
}