import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default class Phone {
    constructor() {
        this.init();
    }

    init() {
        const input = document.querySelectorAll(".phone");
        if (input) {
            input.forEach(item => {
                const iti = intlTelInput(item, {
                    initialCountry: "fr",
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.5/js/utils.js"
                });

                const reset = () => {
                    item.classList.remove("has-error");
                };

                // on blur: validate
                item.addEventListener('blur', () => {
                    reset();
                    if (item.value.trim()) {
                        if (!iti.isValidNumber()) {
                            item.classList.add("has-error");
                            const errorCode = iti.getValidationError();
                        }
                    }
                });

                // on keyup / change flag: reset
                item.addEventListener('change', reset);
                item.addEventListener('keyup', reset);
            });
        }
    }
}