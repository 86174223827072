//import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
import imagesLoaded from "imagesloaded";

export default class Filters {
  constructor() {
    this.init();
  }

  init() {
    let xhr = null,
      $form = $(".js-form"),
      $paged = $(".js-paged"),
      resetForm = $(".reset-form"),
      recipientLabels = $(".labels-form"),
      buttonNext = document.querySelector(".next"),
      pagination = document.getElementById("pagination"),
      queryParams = [];

    // On initialise les étiquettes au load de la page grace aux input checked
    initLabels();

    function abort(xhrVar, $list = null) {
      if (xhrVar !== null) {
        xhrVar.abort();
      }
    }

    // On lance la fonction de filtre selon où on est
    goFilter(
      document.body.classList.contains("page-template-our-products"),
      null,
      ".template-list__products .posts-container",
      ".model-product__item",
      "filter_product",
      ".template-list__products .template-list__count > span.result",
      ".template-list__products .list-items"
    );
    goFilter(
      document.body.classList.contains("page-template-our-applications"),
      document.body.classList.contains("tax-type_of_applications"),
      ".template-list__applications .posts-container",
      ".model-application__item",
      "filter_application",
      ".template-list__applications .template-list__count > span.result",
      ".template-list__applications .list-items"
    );

    // if (pagination) {
    //     pagination.style.display = 'none';
    // }

    function goFilter(
      bodyClass,
      bodyClass2,
      postContainer,
      model,
      ajaxDataAction,
      listCount,
      listItems
    ) {
      if (bodyClass || bodyClass2) {
        let ias;
        // if (document.querySelector(postContainer)) {
        //     if (buttonNext) {
        //         ias = new InfiniteAjaxScroll(postContainer, {
        //             item: model,
        //             //next: '.next',
        //             pagination: '#pagination',
        //             logger: false,
        //             debug: true,
        //             loadOnScroll: false,
        //             //trigger: '.load-more'
        //         });

        //         imagesLoadedAndLayout(postContainer).then(() => {
        //             ias.bind();
        //         });

        //         ias.on('append', (event) => {
        //             let appendFn = event.appendFn;

        //             event.appendFn = (items, parent, last) => {
        //                 return new Promise((resolve) => {
        //                     appendFn(items, parent, last);

        //                     imagesLoadedAndLayout(items).then(resolve);
        //                 });
        //             };
        //         });
        //     }
        // }

        const inputs = $('.multi-filters input[type="checkbox"]');

        inputs.each(function (i, el) {
          if ($(el).is(":checked")) {
            $(this).parents(".accordion-button").removeClass("collapsed");
            $(this).parents(".accordion-collapse").addClass("show");
          }
        });

        inputs.change(function () {
          $paged.val(1);
          // Si notre case est cochée, on ajoute l'etiquette ou on la supprime
          if ($(this).is(":checked")) {
            addLabel($(this));
          } else {
            removeLabel($(this));
          }
          playAjax();
        });

        $(document).on("click", "#pagination .pager__page", function (e) {
          e.preventDefault();
          $paged.val($(this).data("pagination"));
          playAjax();
        });

        function getDataForm() {
          let data = {},
            cpt = 0,
            oldName = null;
          queryParams = [];

          $form.find('input[type="checkbox"]:checked').each(function () {
            let name = $(this).attr("name").replace("[", "").replace("]", "");
            let value = $(this).val();
            if (oldName !== name) {
              if (oldName !== null) {
                cpt++;
              }
              queryParams[cpt] = [];
              queryParams[cpt]["values"] = [];
            }
            queryParams[cpt]["key"] = name;
            queryParams[cpt]["values"].push(value);

            if (oldName !== name) {
              oldName = name;
            }
          });

          data.action = ajaxDataAction;

          $.each(queryParams, function (key, value) {
            data[value["key"]] = value["values"].join("-");
          });
          data.step = $paged.val();
          queryParams.push({
            key: "step",
            values: [data.step],
          });

          return data;
        }

        function playAjax() {
          var data = getDataForm();

          $.ajax({
            url: "/wp-admin/admin-ajax.php",
            type: "GET",
            data,
            beforeSend: function () {
              let previouslyCreatedSmoother = ScrollSmoother.get();

              console.log(previouslyCreatedSmoother);

              previouslyCreatedSmoother.scrollTo("#top", true);
            },
            success: function (result) {
              console.log(result);

              replaceHistoryState(queryParams, window.location.href);
              // On vient jouer sur le bouton tout supprimer avec son compteur
              counter();
              let resultFormatted = JSON.parse(result);
              if (bodyClass) {
                // if (buttonNext) ias.unbind();
                $(listCount).html(resultFormatted.count);
                $(listItems).html(resultFormatted.data);
              }

              ScrollTrigger.refresh();
            },
            error: function (xhr) {
              console.log(xhr.responseText);
            },
          });
        }

        // Au click sur le bouton tout supprimer
        resetForm.click(function () {
          $(".accordion-button").addClass("collapsed");
          $(".accordion-collapse").removeClass("show");

          // On désactive tous les cases cochées
          inputs.prop("checked", false);

          // On lance notre ajax avec toutes les cases décochées
          playAjax();
        });

        // Au click sur une étiquette à supprimer
        $(document).on("click", "button.label-form", function () {
          let myId = $(this).attr("data-id").replace("label-", "");
          $(".multi-filters input#" + myId).trigger("click");
        });
      }
    }

    function imagesLoadedAndLayout(elem) {
      return new Promise((resolve) => {
        imagesLoaded(elem).on("done", () => {
          resolve();
        });
      });
    }

    function replaceHistoryState(element, newUrl) {
      let parsedUrl = new URL(newUrl);

      console.log(element);

      // On supprime par défaut tous les paramètres
      parsedUrl.searchParams.forEach(function (value, key) {
        parsedUrl.searchParams.delete(key);
      });

      // On récupère tous les paramètres du queryParams
      $.each(element, function (key, value) {
        parsedUrl.searchParams.set(value["key"], value["values"].join("-"));
      });

      history.replaceState(
        null,
        null,
        parsedUrl.search.length === 0 ? parsedUrl.href : parsedUrl.search
      );
    }

    /**
     * Initialisation des étiquettes en récupérant les cases déjà cochées
     */
    function initLabels() {
      $('.multi-filters input[type="checkbox"]:checked').each(function () {
        addLabel($(this));
      });
    }

    /**
     * Ajoute une etiquette
     */
    function addLabel(element) {
      recipientLabels.append(
        `<li class="me-2 mb-2"><button class="label-form btn btn-big btn-big-contact btn-big-mw-auto bg-white border border-1 border-gray-4 text-dark fw-normal fs-16 py-2" type="button" data-id="label-` +
          element.attr("id") +
          `">` +
          element.attr("data-name") +
          `<span class="icon-close d-inline-flex ms-2"> <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-570.000000, -1089.000000)" fill="#000000"><path d="M591.657,1109.24 C592.048,1109.63 592.048,1110.27 591.657,1110.66 C591.267,1111.05 590.633,1111.05 590.242,1110.66 L586.006,1106.42 L581.74,1110.69 C581.346,1111.08 580.708,1111.08 580.314,1110.69 C579.921,1110.29 579.921,1109.65 580.314,1109.26 L584.58,1104.99 L580.344,1100.76 C579.953,1100.37 579.953,1099.73 580.344,1099.34 C580.733,1098.95 581.367,1098.95 581.758,1099.34 L585.994,1103.58 L590.292,1099.28 C590.686,1098.89 591.323,1098.89 591.717,1099.28 C592.11,1099.68 592.11,1100.31 591.717,1100.71 L587.42,1105.01 L591.657,1109.24 L591.657,1109.24 Z M586,1089 C577.163,1089 570,1096.16 570,1105 C570,1113.84 577.163,1121 586,1121 C594.837,1121 602,1113.84 602,1105 C602,1096.16 594.837,1089 586,1089 L586,1089 Z"></path></g></g></svg></span></button></li>`
      );
    }

    /**
     * Supprime une etiquette
     */
    function removeLabel(element) {
      recipientLabels
        .find("button[data-id=label-" + element.attr("id") + "]")
        .parent()
        .remove();
    }

    /**
     * Gestion du bouton tout supprimer + compteur des etiquettes
     */
    function counter() {
      let selectedFiltersCount = $(
        '.multi-filters input[type="checkbox"]:checked'
      ).length;
      if (selectedFiltersCount > 0) {
        resetForm
          .find(".selected-counter")
          .html("(" + selectedFiltersCount + ")");
        resetForm.show();
      } else {
        recipientLabels.html("");
        resetForm.hide();
      }
    }

    function AJAX() {}
  }
}
