import '../sass/app.scss';

import "bootstrap/dist/js/bootstrap.bundle.min";
import Carousel from "./components/carousel";
import Tabs from "./components/tabs";
import Filters from "./components/filters";
import Animations from "./components/animations";
import Sticky from "./components/sticky";
import Mobile from "./components/mobile";
import Phone from "./components/phone";
import Viewport from "./components/viewport";

const initScripts = () => {
    new Carousel();
    new Tabs();
    new Filters();
    new Sticky();
    new Animations();
    new Mobile();
    new Phone();
    new Viewport();
}

// initialize scripts
document.addEventListener("DOMContentLoaded", function () {
    initScripts();
});

window.addEventListener('resize', function () {
    new Tabs();
    new Sticky();
    new Mobile();
    new Viewport();
}, false);