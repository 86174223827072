import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
export default class Animations {
    constructor() {
        this.init();
    }

    init() {
        const smoother = ScrollSmoother.create({
            wrapper: "#scrollContainer",
            content: "#content",
            smooth: 2.25,
            smoothTouch: 0.1,
            normalizeScroll: true,
            ignoreMobileResize: true,
            effects: true,
            preventDefault: true
        });

        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth <= 1024 || document.body.classList.contains('page-template-contact')) {
            smoother.kill();
        }

        if (viewportWidth <= 1200) {
            const body = $('body');
            body.css({height: body.height() + 1});
        }

        const moveTop = document.getElementById('move-top');
        if (moveTop) {
            moveTop.addEventListener('click', function (e) {
                e.preventDefault();
                smoother.scrollTo("#header", true, "bottom bottom");
            }, false);
        }

        // Section "Timeline (single-application)"
        const timelineSingleApplication = document.querySelector('.single-application__timeline');
        if (timelineSingleApplication) {
            gsap.matchMedia().add("(min-width: 1199px)", () => {
                ScrollTrigger.create({
                    trigger: ".single-application__timeline-col",
                    start: "top 150px",
                    end: 'bottom 100%',
                    endTrigger: ".single-application__timeline-items",
                    pin: true,
                    pinSpacing: false,
                    invalidateOnRefresh: true
                });
            });
        }

        // Section "Timeline (our-enterprise)"
        const timelineOurEnterprise = document.querySelector('.template-our-enterprise__timeline');
        if (timelineOurEnterprise) {
            gsap.matchMedia().add("(min-width: 1199px)", () => {
                ScrollTrigger.create({
                    trigger: ".template-our-enterprise__timeline-col",
                    start: "top 75px",
                    end: 'bottom center',
                    endTrigger: ".template-our-enterprise__timeline-items",
                    pin: true,
                    pinSpacing: true
                });
            });
        }

        const heightLine = () => {
            $('.template-our-enterprise__timeline-items-item:not(:last-child), .single-application__timeline-items-item:not(:last-child)').each(function () {
                const heightLine = $(this).outerHeight() + 25;
                $(this).find('.template-our-enterprise__timeline-items-item-line, .single-application__timeline-items-item-line').css('height', heightLine);
            });
        }

        heightLine();
        window.addEventListener('resize', function () {
            heightLine();
        }, false);

        // Parallax
        if (document.querySelector('[anim-parallax]')) {
            gsap.matchMedia().add('(min-width: 1025px)', () => {
                gsap.utils.toArray('[anim-parallax="up"]').forEach((section) => {
                    const img = section.querySelector('img');
                    if (img) {
                        gsap.to(img, {
                            yPercent: 20,
                            ease: "none",
                            scrollTrigger: {
                                trigger: img,
                                start: "top center",
                                scrub: true
                            }
                        });
                    }
                });

                gsap.utils.toArray('[anim-parallax="down"]').forEach((section) => {
                    const img = section.querySelector('img');
                    if (img) {
                        gsap.to(img, {
                            yPercent: -20,
                            ease: "none",
                            scrollTrigger: {
                                trigger: img,
                                start: "top center",
                                scrub: true
                            }
                        });
                    }
                });
            });
        }
    }
}