import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Mobile {
    constructor() {
        this.init();
    }

    init() {
        const removeAllSubmenus = () => {
            const submenus = document.querySelectorAll('.navigation-top__submenu');
            submenus.forEach(item => {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                }
            });
        }

        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth <= 1200) {
            const body = document.body,
                burgerMenu = document.getElementById('burger-menu'),
                searchMenu = document.getElementById('search-menu'),
                siteNavigationsMobile = document.getElementById('site-navigations__mobile'),
                siteNavigationsSearch = document.getElementById('site-navigations__search'),
                linkWithChildrens = document.querySelectorAll('#site-navigations__mobile .navigation-top .menu-item.menu-item-has-children > .item-link'),
                backSubmenu = document.querySelectorAll('#site-navigations__mobile .navigation-top .navigation-top__back-submenu'),
                mytap = 'click',
                burgerHandler = function () {
                    if (body.classList.contains('open-menu-burger')) {
                        body.classList.remove('open-menu-search');
                        body.classList.remove('open-menu-burger');
                    } else {
                        body.classList.add('open-menu-burger');
                    }
                    this.classList.toggle('open');
                    siteNavigationsMobile.classList.toggle('open');
                    searchMenu.classList.remove('open');
                    siteNavigationsSearch.classList.remove('open');
                    removeAllSubmenus();
                },
                searchHandler = function () {
                    if (body.classList.contains('open-menu-search')) {
                        body.classList.remove('open-menu-search');
                        body.classList.remove('open-menu-burger');
                    } else {
                        body.classList.add('open-menu-search');
                    }
                    this.classList.toggle('open');
                    siteNavigationsSearch.classList.toggle('open');
                    burgerMenu.classList.remove('open');
                    siteNavigationsMobile.classList.remove('open');
                    removeAllSubmenus();
                }

            burgerMenu.addEventListener(mytap, burgerHandler);
            searchMenu.addEventListener(mytap, searchHandler);

            linkWithChildrens.forEach(link => {
                link.addEventListener('click', function () {
                    this.parentElement.querySelector('.navigation-top__submenu').classList.add('active');
                });
            });

            backSubmenu.forEach(link => {
                link.addEventListener('click', function (e) {
                    removeAllSubmenus();
                });
            });
        }
    }
}